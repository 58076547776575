import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          {/* <CFImage src={about} w="100%" alt="About" /> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          h="550px"
          maxWidth="1400px"
          image={`url(${about}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          column
          alignCenter
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
