import React, { useState } from 'react'
import { css } from 'emotion'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFIcon,
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, mobileLogo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          w="100%"
          pv="8px"
          bg="rgba(0, 0, 0, 0.8)"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
        >
          <CFImage w="50%" src={mobileLogo} alt="Jumak Ottawa Logo" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={98} bg="#353535" h="50px" pv="5px">
          <CFView row justifyBetween alignCenter w="100%" maxWidth="1400px">
            <CFImage
              ml="50px"
              w="395px"
              src={logo}
              alt="Jumak Ottawa Logo"
              zIndex={98}
            />
            <CFView mr="50px" mt="-135px" bold row center>
              <CFView mt="1px">
                <CFIcon icon="phone" color="#EBC14C" fontSize={22} mr="10px" />
              </CFView>
              <CFLink href={`tel:${6132305586}`} className={styles.hyperlink}>
                (613) 230-5586
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

const styles = {
  hyperlink: css({
    color: '#EBC14C',
    visitedColor: '#EBC14C',
    textDecoration: 'none',
  }),
}
